import React from "react";
import {IonReactRouter} from "@ionic/react-router"
import {Route} from "react-router-dom";
import {IntlProvider} from "react-intl";
import View from "../screen/View";
import Insert from "../screen/Insert";
import axios from "axios";
import {LinearProgress} from "@mui/material";
import style from "../App.module.css"

// import BaseComponent from "../interfaces/BaseComponent";
// import LoadingScreen from "../screens/Loading/LoadingScreen";
// import {LOADING_SCREEN, LOGGED_IN_ROUTER, LOGGED_OUT_ROUTER} from "../constants/NavigationConstants";
//
// import LoggedInRouter from "./LoggedIn/LoggedInRouter";
// import AuthModule from "../submodules/modules/auth/AuthModule";
// import {AUTH_TOKEN_ID} from "../constants/CommonConstants";
// import packageJson from "../../package.json";
// import {logo} from "../assets/images/logos/sidebar/logo";

class BaseRouter extends React.Component {
    state = {
        messages: {},
        loadingState: true,
        loadingChangeLanguage:true
    }

    async componentDidMount() {
        await this.changeTranslation()
    }

    changeTranslation = async (lang = "en") => {
        // 0. Recupero le traduzioni aggiornate
        try {
            this.setState({loadingChangeLanguage: true})
            let res = await axios.request({
                url: "https://salty.tech4care.eu/api/proxy/translation.php?locale=" + lang,
                method: "GET",
                headers:{
                    APIKEY:"AJKBFDJKAHFDB769JAHDNKL"
                }
            });

            this.setState({
                messages: {en: res.data,},
                loadingState:false
            })
        } catch (error) {
            console.error("Error retrieving translations", error, error.response)
        } finally {
            this.setState({loadingChangeLanguage: false})
        }
    }

    render() {
        let {messages,loadingState,loadingChangeLanguage} = this.state;
        if(loadingState){
            return null
        }
        return (
            <IntlProvider
                messages={messages.en}
                locale={"en"}
                onError={(err) => {
                    console.error("Missing translation: ", err)
                }}
            >
                <IonReactRouter>
                    {loadingChangeLanguage &&  <div className={style.loadingModal}>
                        <LinearProgress/>
                    </div>}
                    <Route exact path={"/insert"}>
                        <Insert changeTranslation={this.changeTranslation}/>
                    </Route>
                    <Route exact path={"/view"}>
                        <View
                            changeTranslation={this.changeTranslation}
                        />
                    </Route>
                </IonReactRouter>
            </IntlProvider>
        )
    }
}


export default BaseRouter
