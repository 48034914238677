import React, {Component} from 'react';
import queryString from "query-string";
import {withRouter} from "react-router";
import moment from "moment";
import axios from "axios";
import style from "./../asset/styles/view.module.css";
import {CheckCircle, Error, ExpandLess, ExpandMore, RemoveDone, Warning} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";
import {IconButton, Tab, Tabs} from "@mui/material";


const format = "YYYY-MM-DD HH:mm:ss"

class View extends Component {
    state = {
        startDate: moment().subtract(1, "year"),
        endDate: moment().endOf("week"),
        type: "pressure",
        loading: true,
        tabIndex: 0,
        expandedCard: [],
        secondaryTabValues: [],
        values: []
    }

    componentDidMount() {
        let values = queryString.parse(this.props.location.search)

        this.setState({...values}, this.retrieveMeasurement)
    }


    retrieveMeasurement = async () => {
        try {
            let {idPaziente, startDate, endDate, type, language, token} = this.state;
            let {changeTranslation} = this.props;
            changeTranslation(language)


            let values = [], temp = {}, arrayData = [], resSecondaryCall, secondaryTabValues = [],
                url = "https://hyperhealth.gesan.it/api/hyperhealth?idpaziente=" + idPaziente + "&dal=" + encodeURI(startDate.format(format)) + "&al=" + encodeURI(endDate.format(format)),
                urlSecondCall = "https://hyperhealth.gesan.it/api/pazientequestionari?idAnagrafica=" + idPaziente + "&language=" + language
            switch (type) {
                case "sleep":
                    arrayData = ["total_sleep_time", "wakeupcount"]
                    break;
                case "pressure":
                    arrayData = ["Diastolic Blood Pressure (mmHg)", "Systolic Blood Pressure (mmHg)"]
                    break;
                case "bmi":
                    arrayData = ["Weight (kg)"]
                    break;
                case "activity":
                    arrayData = ["steps"]
                    break;
                case "therapy":
                    url = "https://hyperhealth.gesan.it/api/pazientiterapia?language=" + language + "&idPaziente=" + idPaziente
                    break;
                case "habits":
                    arrayData = ["25"]
                    url = "https://hyperhealth.gesan.it/api/pazientequestionari?idAnagrafica=" + idPaziente + "&language=" + language
                    break
                case "diet":
                    arrayData = ["24"]
                    url = "https://hyperhealth.gesan.it/api/pazientequestionari?idAnagrafica=" + idPaziente + "&language=" + language
                    break;
                default:
                    break;
            }

            let config = {
                method: 'post',
                url: "https://salty.tech4care.eu/api/proxy/proxy.php",
                data: {
                    url,
                    token
                },
                headers: {
                    "APIKEY": "AJKBFDJKAHFDB769JAHDNKL",
                    "USERID": idPaziente
                }
            };
            let res = await axios(config)
            console.info("chiamata api url: " + url)
            console.info("risposta api url: ", res.data)

            switch (type) {
                case "pressure":
                    res.data.data.forEach(x => {
                        if (arrayData.includes(x.measurename)) {
                            if (temp[x.istant]) {
                                temp[x.istant][x.measurename] = x.value;
                            } else {
                                temp[x.istant] = {[x.measurename]: x.value};
                            }
                            if ("Diastolic Blood Pressure (mmHg)" == x.measurename) {
                                if (!temp[x.istant].alertStatusD) {
                                    temp[x.istant].alertStatusD = x.range
                                } else if (temp[x.istant].alertStatusD === "NORMAL") {
                                    temp[x.istant].alertStatusD = x.range
                                } else if (temp[x.istant].alertStatusD === "WARNING" && x.range === "ALERT") {
                                    temp[x.istant].alertStatusD = x.range
                                } else if (temp[x.istant].alertStatusD === "ALERT") {
                                    //Non fare nulla
                                }
                            } else {
                                if (!temp[x.istant].alertStatus) {
                                    temp[x.istant].alertStatus = x.range
                                } else if (temp[x.istant].alertStatus === "NORMAL") {
                                    temp[x.istant].alertStatus = x.range
                                } else if (temp[x.istant].alertStatus === "WARNING" && x.range === "ALERT") {
                                    temp[x.istant].alertStatus = x.range
                                } else if (temp[x.istant].alertStatus === "ALERT") {
                                    //Non fare nulla
                                }
                            }
                            if (!temp[x.istant].alertStatusG) {
                                temp[x.istant].alertStatusG = x.range
                            } else if (temp[x.istant].alertStatusG === "NORMAL") {
                                temp[x.istant].alertStatusG = x.range
                            } else if (temp[x.istant].alertStatusG === "WARNING" && x.range === "ALERT") {
                                temp[x.istant].alertStatusG = x.range
                            } else if (temp[x.istant].alertStatusG === "ALERT") {
                                //Non fare nulla
                            }
                        }
                    })
                    for (let key in temp) {
                        values.push({
                            date: moment.utc(parseInt(key)).local(),
                            ...temp[key]
                        });
                    }


                    if (type === "activity") {
                        arrayData = ["22"]
                        resSecondaryCall = await axios({
                            method: 'post',
                            url: "https://salty.tech4care.eu/api/proxy/proxy.php",
                            data: {
                                url: urlSecondCall,
                                token
                            },
                            headers: {
                                "APIKEY": "AJKBFDJKAHFDB769JAHDNKL",
                                "USERID": idPaziente
                            }
                        })
                        resSecondaryCall.data.data.forEach(x => {
                            if (arrayData.includes(x.idQuestionario) && x.dataOraRisposta && x.risposta) {
                                secondaryTabValues.push({
                                    date: moment.utc(parseInt(x.dataOraRisposta)).local(),
                                    ...JSON.parse(x.risposta)
                                })
                            }
                        })
                        console.info("survey", secondaryTabValues)
                    }


                    this.setState({
                        values,
                        secondaryTabValues,
                        loading: false
                    })

                    break;
                case "sleep":
                    res.data.data.forEach(x => {
                        if (arrayData.includes(x.measurename)) {
                            if (temp[x.istant]) {
                                temp[x.istant][x.measurename] = x.value;
                            } else {
                                temp[x.istant] = {[x.measurename]: x.value};
                            }
                            if ("total_sleep_time" == x.measurename) {
                                if (!temp[x.istant].alertStatusD) {
                                    temp[x.istant].alertStatusD = x.range
                                } else if (temp[x.istant].alertStatusD === "NORMAL") {
                                    temp[x.istant].alertStatusD = x.range
                                } else if (temp[x.istant].alertStatusD === "WARNING" && x.range === "ALERT") {
                                    temp[x.istant].alertStatusD = x.range
                                } else if (temp[x.istant].alertStatusD === "ALERT") {
                                    //Non fare nulla
                                }
                            } else {
                                if (!temp[x.istant].alertStatus) {
                                    temp[x.istant].alertStatus = x.range
                                } else if (temp[x.istant].alertStatus === "NORMAL") {
                                    temp[x.istant].alertStatus = x.range
                                } else if (temp[x.istant].alertStatus === "WARNING" && x.range === "ALERT") {
                                    temp[x.istant].alertStatus = x.range
                                } else if (temp[x.istant].alertStatus === "ALERT") {
                                    //Non fare nulla
                                }
                            }

                            if (!temp[x.istant].alertStatusG) {
                                temp[x.istant].alertStatusG = x.range
                            } else if (temp[x.istant].alertStatusG === "NORMAL") {
                                temp[x.istant].alertStatusG = x.range
                            } else if (temp[x.istant].alertStatusG === "WARNING" && x.range === "ALERT") {
                                temp[x.istant].alertStatusG = x.range
                            } else if (temp[x.istant].alertStatusG === "ALERT") {
                                //Non fare nulla
                            }
                        }
                    })
                    for (let key in temp) {
                        values.push({
                            date: moment.utc(parseInt(key)).local(),
                            ...temp[key]
                        });
                    }


                    if (type === "activity") {
                        arrayData = ["22"]
                        resSecondaryCall = await axios({
                            method: 'post',
                            url: "https://salty.tech4care.eu/api/proxy/proxy.php",
                            data: {
                                url: urlSecondCall,
                                token
                            },
                            headers: {
                                "APIKEY": "AJKBFDJKAHFDB769JAHDNKL",
                                "USERID": idPaziente
                            }
                        })
                        resSecondaryCall.data.data.forEach(x => {
                            if (arrayData.includes(x.idQuestionario) && x.dataOraRisposta && x.risposta) {
                                secondaryTabValues.push({
                                    date: moment.utc(parseInt(x.dataOraRisposta)).local(),
                                    ...JSON.parse(x.risposta)
                                })
                            }
                        })
                        console.info("survey", secondaryTabValues)
                    }


                    this.setState({
                        values,
                        secondaryTabValues,
                        loading: false
                    })

                    break;
                case "bmi":
                case "activity":
                    res.data.data.forEach(x => {
                        if (arrayData.includes(x.measurename)) {
                            if (temp[x.istant]) {
                                temp[x.istant][x.measurename] = x.value;
                            } else {
                                temp[x.istant] = {[x.measurename]: x.value};
                            }
                            if (!temp[x.istant].alertStatus) {
                                temp[x.istant].alertStatus = x.range
                            } else if (temp[x.istant].alertStatus === "NORMAL") {
                                temp[x.istant].alertStatus = x.range
                            } else if (temp[x.istant].alertStatus === "WARNING" && x.range === "ALERT") {
                                temp[x.istant].alertStatus = x.range
                            } else if (temp[x.istant].alertStatus === "ALERT") {
                                //Non fare nulla
                            }
                        }
                    })
                    for (let key in temp) {
                        values.push({
                            date: moment.utc(parseInt(key)).local(),
                            ...temp[key]
                        });
                    }


                    if (type === "activity") {
                        arrayData = ["22"]
                        resSecondaryCall = await axios({
                            method: 'post',
                            url: "https://salty.tech4care.eu/api/proxy/proxy.php",
                            data: {
                                url: urlSecondCall,
                                token
                            },
                            headers: {
                                "APIKEY": "AJKBFDJKAHFDB769JAHDNKL",
                                "USERID": idPaziente
                            }
                        })
                        resSecondaryCall.data.data.forEach(x => {
                            if (arrayData.includes(x.idQuestionario) && x.dataOraRisposta && x.risposta) {
                                secondaryTabValues.push({
                                    date: moment.utc(parseInt(x.dataOraRisposta)).local(),
                                    ...JSON.parse(x.risposta)
                                })
                            }
                        })
                        console.info("survey", secondaryTabValues)
                    }


                    this.setState({
                        values,
                        secondaryTabValues,
                        loading: false
                    })

                    break;
                case "therapy":
                    let active = [], others = []
                    res.data.data.forEach(x => {
                        if (x.stato === "ATTIVA") {
                            active.push({
                                name: x.farmaco,
                                frequency: x.frequenza,
                                note: x.note,
                                quantity: x.quantita,
                                format: x.posologia,
                                state: x.stato,
                                id: x.id,
                                startDate: x.dataInizio,
                                endDate: x.dataFine
                            });
                        } else {
                            others.push({
                                name: x.farmaco,
                                frequency: x.frequenza,
                                note: x.note,
                                quantity: x.quantita,
                                format: x.posologia,
                                state: x.stato,
                                id: x.id,
                                startDate: x.dataInizio,
                                endDate: x.dataFine
                            });
                        }
                    })

                    values = [...active, ...others];

                    if (values.length > 0) {
                        resSecondaryCall = await axios({
                            method: 'post',
                            url: "https://salty.tech4care.eu/api/proxy/proxy.php",
                            data: {
                                url: "https://hyperhealth.gesan.it/api/pazienteterapiesomministrazioni?idTerapia=" + values[0].id + "&language=en&idPaziente=" + idPaziente,
                                token
                            },
                            headers: {
                                "APIKEY": "AJKBFDJKAHFDB769JAHDNKL",
                                "USERID": idPaziente
                            }
                        })

                        secondaryTabValues = resSecondaryCall.data.data.map(x => {
                            return {
                                id: x.id,
                                state: x.stato,
                                quantity: x.quantitaAssunta,
                                therapyId: x.idTerapia,
                                date: x.statoDataOra
                            }
                        })
                        values.forEach(x => {
                            secondaryTabValues.forEach((y, yIndex) => {
                                if (x.id === y.therapyId) {
                                    secondaryTabValues[yIndex].name = x.name
                                    secondaryTabValues[yIndex].format = x.format

                                }
                            })
                        })
                    }

                    this.setState({
                        values,
                        secondaryTabValues,
                        loading: false
                    })
                    break;
                case "habits":
                case "diet":
                    res.data.data.forEach(x => {
                        if (arrayData.includes(x.idQuestionario) && x.dataOraRisposta && x.risposta) {
                            values.push({
                                date: moment.utc(parseInt(x.dataOraRisposta)).local(),
                                ...JSON.parse(x.risposta)
                            })
                        }
                    })
                    this.setState({
                        values,
                        loading: false
                    })
                    break;
                default:
                    this.setState({
                        values: res.data.data,
                        loading: false
                    })
                    break;
            }
        } catch (e) {
            console.error("ERROR RESPONSE: ", e)
        }

    }
    renderDietLabel = (value) => {
        switch (parseInt(value)) {
            case 0:
                return <FormattedMessage id={"dashboard.me.generic.skipped"}/>
            case 1:
                return <FormattedMessage id={"dashboard.me.generic.light"}/>
            case 2:
                return <FormattedMessage id={"dashboard.me.generic.normal"}/>
            case 3:
                return <FormattedMessage id={"dashboard.me.generic.abundant"}/>
            case 4:
                return <FormattedMessage id={"dashboard.me.generic.excessive"}/>
            default:
                return <FormattedMessage id={"dashboard.me.generic.skipped"}/>;
        }
    }

    render() {
        let {values, type, loading, tabIndex, expandedCard, secondaryTabValues} = this.state;
        if (loading) {
            return <div className={style.loadingModal}>
                <div className={style.loadingContainer}>
                    <span
                        className={style.text2}>
                        <FormattedMessage id={"dashboard.me.view.loading"}/>
                    </span>
                </div>
            </div>
        }
        switch (type) {
            case "sleep":
                return (<div className={style.background}>
                    {values && values.reverse().map((x, index) => {
                        let icon;
                        switch (x.alertStatus) {
                            case "WARNING":
                                icon = <Warning className={style.iconSide} color={"warning"}
                                    // sx={{color: yellow[500]}}
                                />;
                                break;
                            case "ALERT":
                                icon = <Error className={style.iconSide} color={"error"}
                                    // sx={{color: red[500]}}
                                />;
                                break;
                            case "NORMAL":
                            default:
                                icon = <CheckCircle className={style.iconSide} color={"success"}
                                    // sx={{color: green[500]}}
                                />;
                                break;
                        }
                        let color;
                        switch (x.alertStatusG) {
                            case "WARNING":
                                color = "#ed6c02";
                                break;
                            case "ALERT":
                                color = "#D32F2F";
                                break;
                            case "NORMAL":
                            default:
                                color = "#2E7D32"
                                break;
                        }
                        let iconWakeupcount;
                        switch (x.alertStatusD) {
                            case "WARNING":
                                iconWakeupcount = <Warning className={style.iconSide} color={"warning"}
                                    // sx={{color: yellow[500]}}
                                />;
                                break;
                            case "ALERT":
                                iconWakeupcount = <Error className={style.iconSide} color={"error"}
                                    // sx={{color: red[500]}}
                                />;
                                break;
                            case "NORMAL":
                            default:
                                iconWakeupcount = <CheckCircle className={style.iconSide} color={"success"}
                                    // sx={{color: green[500]}}
                                />;
                                break;
                        }
                        return <div key={"card-" + index} className={style.card} style={{boxShadow: "0px 1px 4px "+color}}>
                            <span className={style.text2}>{x.date.format("DD/MM/YYYY")}</span>
                            <span
                                className={style.text3}>
                                {/*Sleep time:*/}
                                <FormattedMessage id={"dashboard.me.view.sleepTime"}/>
                                {/*{moment.utc(x.total_sleep_time * 1000).format('HH:mm')}*/}
                            </span>
                            <span className={style.iconContainer}>
                                {icon}
                                <span className={style.text1}>
                                    {/*Sleep time:*/}
                                <FormattedMessage id={"dashboard.me.view.sleepTimeValue"} values={{
                                    value: moment.utc(x.total_sleep_time * 1000).format('HH'),
                                    value1: moment.utc(x.total_sleep_time * 1000).format('mm')
                                }}/>
                                {/*{moment.utc(x.total_sleep_time * 1000).format('HH:mm')}*/}
                                </span>
                            </span>
                            <span className={style.text3}>
                                {/*Wake up count: */}
                                <FormattedMessage id={"dashboard.me.view.wakeUpCount"}/>

                            </span>
                            <span className={style.iconContainer}>
                                {iconWakeupcount}
                            <span
                                className={style.text1}>
                                {/*Sleep time:*/}
                                {x.wakeupcount}
                                {/*{moment.utc(x.total_sleep_time * 1000).format('HH:mm')}*/}
                            </span>
                            </span>
                        </div>
                    })}
                </div>);
            case "pressure":
                return (<div className={style.background}>
                    {values && values.reverse().map((x, index) => {
                        let icon;
                        switch (x.alertStatus) {
                            case "WARNING":
                                icon = <Warning className={style.iconSide} color={"warning"}
                                    // sx={{color: yellow[500]}}
                                />;
                                break;
                            case "ALERT":
                                icon = <Error className={style.iconSide} color={"error"}
                                    // sx={{color: red[500]}}
                                />;
                                break;
                            case "NORMAL":
                            default:
                                icon = <CheckCircle className={style.iconSide} color={"success"}
                                    // sx={{color: green[500]}}
                                />;
                                break;
                        }
                        let color;
                        switch (x.alertStatusG) {
                            case "WARNING":
                                color = "#ed6c02";
                                break;
                            case "ALERT":
                                color = "#D32F2F";
                                break;
                            case "NORMAL":
                            default:
                                color = "#2E7D32"
                                break;
                        }
                        let iconDiastolic;
                        switch (x.alertStatusD) {
                            case "WARNING":
                                iconDiastolic = <Warning className={style.iconSide} color={"warning"}
                                    // sx={{color: yellow[500]}}
                                />;
                                break;
                            case "ALERT":
                                iconDiastolic = <Error className={style.iconSide} color={"error"}
                                    // sx={{color: red[500]}}
                                />;
                                break;
                            case "NORMAL":
                            default:
                                iconDiastolic = <CheckCircle className={style.iconSide} color={"success"}
                                    // sx={{color: green[500]}}
                                />;
                                break;
                        }
                        return <div key={"card-" + index} className={style.card} style={{boxShadow: "0px 1px 4px "+color}}>

                            <span className={style.text2}>{x.date.format("DD/MM/YYYY HH:mm")}</span>
                            <span
                                className={style.text3}>
                                {/*Systolic:*/}
                                <FormattedMessage id={"dashboard.me.view.systolic"}
                                />
                            </span>
                            <span className={style.iconContainer}>
                                {icon}
                                <span
                                    className={style.text1}>
                                    {/*Systolic:*/}
                                    <FormattedMessage id={"dashboard.me.view.systolicValue"}
                                                      values={{value: x["Systolic Blood Pressure (mmHg)"]}}/>
                                </span>
                            </span>
                            <span className={style.text3}>
                                {/*Diastolic:*/}
                                <FormattedMessage id={"dashboard.me.view.diastolic"}
                                />
                            </span>
                            <span className={style.iconContainer}>
                                {iconDiastolic}
                                <span className={style.text1}>
                                    {/*Diastolic:*/}
                                    <FormattedMessage id={"dashboard.me.view.diastolicValue"}
                                                      values={{value: x["Diastolic Blood Pressure (mmHg)"]}}/>
                                </span>
                            </span>
                        </div>
                    })}
                </div>);
            case "bmi":
                return (<div className={style.background}>
                    {values && values.reverse().map((x, index) => {
                        let icon;
                        switch (x.alertStatus) {
                            case "WARNING":
                                icon = <Warning className={style.icon} color={"warning"}
                                    // sx={{color: yellow[500]}}
                                />;
                                break;
                            case "ALERT":
                                icon = <Error className={style.icon} color={"error"}
                                    // sx={{color: red[500]}}
                                />;
                                break;
                            case "NORMAL":
                            default:
                                icon = <CheckCircle className={style.icon} color={"success"}
                                    // sx={{color: green[500]}}
                                />;
                                break;
                        }
                        return <div key={"card-" + index} className={style.card}>
                            {icon}
                            <span className={style.text2}>{x.date.format("DD/MM/YYYY HH:mm")}</span>
                            <span
                                className={style.text3}>
                                {/*Systolic:*/}
                                <FormattedMessage id={"dashboard.me.view.weight"}/>
                                </span> <span
                            className={style.text1}>
                                {/*Systolic:*/}
                            <FormattedMessage id={"dashboard.me.view.weightValue"} values={{
                                value: x["Weight (kg)"]
                            }}/>
                                </span>
                        </div>
                    })}
                </div>);
            case "activity":
                return (<div className={style.background}>
                    <Tabs value={tabIndex} className={style.tabs}
                          variant="fullWidth"
                          onChange={(event, tabIndex) => {
                              this.setState({tabIndex})
                          }} aria-label="tabsActivity">
                        <Tab label={<FormattedMessage id={"dashboard.me.view.tabs.steps"}/>} value={0}/>
                        <Tab label=<FormattedMessage id={"dashboard.me.view.tabs.survey"}/> value={1}/>
                    </Tabs>
                    {tabIndex === 0 && values && values.reverse().map((x, index) => {
                        let icon;
                        switch (x.alertStatus) {
                            case "WARNING":
                                icon = <Warning className={style.icon} color={"warning"}
                                    // sx={{color: yellow[500]}}
                                />;
                                break;
                            case "ALERT":
                                icon = <Error className={style.icon} color={"error"}
                                    // sx={{color: red[500]}}
                                />;
                                break;
                            case "NORMAL":
                            default:
                                icon = <CheckCircle className={style.icon} color={"success"}
                                    // sx={{color: green[500]}}
                                />;
                                break;
                        }
                        return <div key={"card-" + index} className={style.card}>
                            {icon}
                            <span className={style.text2}>{x.date.format("DD/MM/YYYY HH:mm")}</span>
                            <span
                                className={style.text3}>
                                {/*Systolic:*/}
                                <FormattedMessage id={"dashboard.me.view.steps"}/>
                                </span> <span
                            className={style.text1}>
                                {/*Systolic:*/}
                            <FormattedMessage id={"dashboard.me.view.stepsValue"} values={{
                                value: x["steps"]
                            }}/>
                                </span>
                        </div>
                    })}
                    {tabIndex === 1 && secondaryTabValues && secondaryTabValues.map((x, index) => {
                        return <div key={"card-" + index} className={style.card}>
                            <span className={style.text2}>{x.date.format("DD/MM/YYYY HH:mm")}</span>
                            <span
                                className={style.text3}>
                                <FormattedMessage
                                    id={"dashboard.me.view.numberOfActivity"}
                                />
                                 <span
                                     className={style.text1} style={{marginLeft: "10px"}}>
                                     {x["number_of_activity"]}

                                        </span>
                            </span>
                            <span
                                className={style.text3}>
                                <FormattedMessage
                                    id={"dashboard.me.view.minutesOfActivity"}
                                />
                                 <span
                                     className={style.text1} style={{marginLeft: "10px"}}>
                                     <FormattedMessage
                                         id={"dashboard.me.view.minutesOfActivityValue"}
                                         values={{
                                             value: x["minute_of_activity"]
                                         }}
                                     />
                                        </span>
                            </span>

                        </div>
                    })}
                </div>);
            case "therapy":

                return (<div className={style.background}>
                    <Tabs value={tabIndex} className={style.tabs}
                          variant="fullWidth"
                          onChange={(event, tabIndex) => {
                              this.setState({tabIndex})
                          }} aria-label="tabsTherapy">
                        <Tab label={<FormattedMessage id={"dashboard.me.view.tabs.therapy"}/>} value={0}/>
                        <Tab label=<FormattedMessage id={"dashboard.me.view.tabs.doses"}/> value={1}/>
                    </Tabs>
                    {tabIndex === 0 && values && values.map((x, index) => {
                        let isExpanded = this.state.expandedCard.includes(x.id)
                        let note = x["note"]
                        if (!isExpanded && note.length > 0) {
                            note = "..."
                        }
                        let icon;
                        switch (x.state) {
                            case "TERMINATA":
                                icon = <RemoveDone className={style.icon} color={"warning"}
                                    // sx={{color: yellow[500]}}
                                />;
                                break;
                            case "SOSPESA":
                                icon = <Error className={style.icon} color={"error"}
                                    // sx={{color: red[500]}}
                                />;
                                break;
                            case "ATTIVA":
                            default:
                                icon = <CheckCircle className={style.icon} color={"success"}
                                    // sx={{color: green[500]}}
                                />;
                                break;
                        }
                        return <div key={"card-" + index} className={style.card}>
                            <span className={style.text1}><FormattedMessage
                                id={"dashboard.me.view.therapyState." + x.state}/></span>
                            {icon}
                            <span
                                className={style.text3}>
                                {moment(x["startDate"]).format("DD/MM/YYYY")}
                                {"-"}
                                {moment(x["endDate"]).format("DD/MM/YYYY")}
                                </span>
                            <span
                                className={style.text1}>
                                {x.name[0] + x.name.substring(1, x.name.length).toLowerCase()}
                                </span>
                            <span
                                className={style.text2}>
                                {x["frequency"]}
                                </span>
                            <span
                                className={style.text3} style={{justifyContent: "flex-end", paddingRight: "10px"}}>

                                {x["quantity"]}
                                {" "}
                                {x["format"]}
                            </span>
                            {note.length > 0 && <span
                                className={style.text2} style={{justifyContent: "space-between"}}>
                                <label>
                                {note}
                                </label>
                                <IconButton onClick={() => {
                                    if (expandedCard.includes(x.id)) {
                                        let index = expandedCard.indexOf(x.id);
                                        expandedCard.splice(index, 1)
                                    } else {
                                        expandedCard.push(x.id)
                                    }

                                    this.setState({

                                        expandedCard
                                    })
                                }}>
                                    {isExpanded ? <ExpandLess/> : <ExpandMore/>}
                                </IconButton>
                                </span>}
                        </div>
                    })}
                    {tabIndex === 1 && secondaryTabValues && secondaryTabValues.map((x, index) => {
                        let icon;
                        switch (x.state) {
                            case "NON SOMMINISTRATA":
                                icon = <RemoveDone className={style.icon} color={"error"}
                                    // sx={{color: yellow[500]}}
                                />;
                                break;
                            case "RIFIUTATA":
                                icon = <Error className={style.icon} color={"warning"}
                                    // sx={{color: red[500]}}
                                />;
                                break;
                            case "SOMMINISTRATA":
                            default:
                                icon = <CheckCircle className={style.icon} color={"success"}
                                    // sx={{color: green[500]}}
                                />;
                                break;
                        }
                        return <div key={"card-" + index} className={style.card}>
                            <span className={style.text1}><FormattedMessage
                                id={"dashboard.me.view.therapyState." + x.state}/></span>
                            {icon}
                            <span
                                className={style.text3}>
                                {moment(x["date"]).format("DD/MM/YYYY HH:mm")}
                                </span>
                            <span
                                className={style.text1}>
                                {x.name[0] + x.name.substring(1, x.name.length).toLowerCase()}
                                </span>
                            <span
                                className={style.text3} style={{justifyContent: "flex-end", paddingRight: "10px"}}>

                                {x["quantity"]}
                                {" "}
                                {x["format"]}
                            </span>
                        </div>
                    })}
                </div>)
            case "diet":
                return (<div className={style.background}>
                    {values && values.map((x, index) => {
                        return <div key={"card-" + index} className={style.card}>
                            <span className={style.text2}>{x.date.format("DD/MM/YYYY HH:mm")}</span>
                            <span
                                className={style.text3}>
                                <FormattedMessage
                                    id={"dashboard.me.view.breakfast"}

                                />
                                  <span
                                      className={style.text1} style={{marginLeft: "10px"}}>
                                        {this.renderDietLabel(x["Colazione"])}
                                        </span>
                            </span>
                            <span
                                className={style.text3}>
                                <FormattedMessage
                                    id={"dashboard.me.view.brunch"}
                                />
                                  <span
                                      className={style.text1} style={{marginLeft: "10px"}}>
                                        {this.renderDietLabel(x["Spuntino_meta_mattinata"])}
                                        </span>
                            </span>
                            <span
                                className={style.text3}>
                                <FormattedMessage
                                    id={"dashboard.me.view.lunch"}
                                />
                                <span
                                    className={style.text1} style={{marginLeft: "10px"}}>
                                        {this.renderDietLabel(x["Pranzo"])}
                                        </span>
                            </span>
                            <span
                                className={style.text3}>
                                <FormattedMessage
                                    id={"dashboard.me.view.snack"}

                                />
                                   <span
                                       className={style.text1} style={{marginLeft: "10px"}}>
                                        {this.renderDietLabel(x["Spuntino_pomeridiano"])}
                                        </span>
                            </span>
                            <span
                                className={style.text3}>
                                <FormattedMessage
                                    id={"dashboard.me.view.dinner"}
                                />
                                   <span
                                       className={style.text1} style={{marginLeft: "10px"}}>
                                        {this.renderDietLabel(x["Cena"])}
                                        </span>
                            </span>
                            <span
                                className={style.text3}>
                                <FormattedMessage
                                    id={"dashboard.me.view.dinnerSnack"}
                                />
                                  <span
                                      className={style.text1} style={{marginLeft: "10px"}}>
                                        {this.renderDietLabel(x["Spuntino_serale"])}
                                        </span>
                            </span>
                            <span
                                className={style.text3}>
                                <FormattedMessage
                                    id={"dashboard.me.view.salt"}
                                />
                                 <span
                                     className={style.text1} style={{marginLeft: "10px"}}>
                                     <FormattedMessage
                                         id={"dashboard.me.view.saltValue"}
                                         values={{value: x["Sale"]}}
                                     />
                                        </span>
                            </span>
                            <span
                                className={style.text3}>
                                <FormattedMessage
                                    id={"dashboard.me.view.water"}
                                />
                                 <span
                                     className={style.text1} style={{marginLeft: "10px"}}>
                                         <FormattedMessage
                                             id={"dashboard.me.view.waterValue"}
                                             values={{value: x["Acqua"]}}
                                         />
                                        </span>
                            </span>
                        </div>
                    })}
                </div>);
            case "habits":
                return (<div className={style.background}>
                    {values && values.map((x, index) => {
                        return <div key={"card-" + index} className={style.card}>
                            <span className={style.text2}>{x.date.format("DD/MM/YYYY HH:mm")}</span>
                            <span
                                className={style.text3}>
                                <FormattedMessage
                                    id={"dashboard.me.view.cigarettes"}
                                />
                                 <span
                                     className={style.text1} style={{marginLeft: "10px"}}>
                                     {x["Sigarette"]}
                                        </span>
                            </span>
                            <span
                                className={style.text3}>
                                <FormattedMessage
                                    id={"dashboard.me.view.alcohol"}
                                />
                                 <span
                                     className={style.text1} style={{marginLeft: "10px"}}>
                                     {x["Alcool"]}

                                        </span>
                            </span> <span
                            className={style.text3}>
                                <FormattedMessage
                                    id={"dashboard.me.view.coffee"}
                                />
                                 <span
                                     className={style.text1} style={{marginLeft: "10px"}}>
                                       {x["Caffe"]}

                                        </span>
                            </span>
                        </div>
                    })}
                </div>);
            default:
                return (<div>
                    {values && values.map(x => {
                        return <div>
                            <h4>Ho una misurazione di: {x.measurename}</h4>
                            <p>Range: {x.range}</p>
                            <p>value: {x.value}</p>
                        </div>
                    })}
                </div>);

        }

    }
}

export default withRouter(View);