import './App.module.css';
import BaseRouter from "./rounting/BaseRouter";
import CacheBuster from "./CacheBuster";

function App() {
    return (
        <CacheBuster>
            {({loading, isLatestVersion, refreshCacheAndReload}) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    refreshCacheAndReload();
                }
                return (
                    <BaseRouter/>
                )
            }}
        </CacheBuster>
    );
}

export default App;
