import React, {Component} from 'react';
import queryString from "query-string";
import {withRouter} from "react-router";
import {Box, Button, CircularProgress, Grid, IconButton, Slider, TextField} from "@mui/material";
import style from "./../asset/styles/insert.module.css";
import * as SurveyPDF from "survey-pdf";
import axios from "axios";
import {FormattedMessage} from "react-intl";
import {CheckCircle, Error, RemoveDone, Warning} from "@mui/icons-material";
import moment from "moment";

const structurePressure = {
    "title": "BLOOD PRESSURE", "pages": [{
        "name": "Blood Pressure", "elements": [{
            "type": "text",
            "name": "Systolic Blood Pressure (mmHg)",
            "title": "Please, measure your sistolic blood pressure and input its value in mmHg",
            "inputType": "number"
        }, {
            "type": "text",
            "name": "Diastolic Blood Pressure (mmHg)",
            "title": "Please, measure your diastolic blood pressure and input its value in mmHg",
            "inputType": "number"
        }], "title": "Blood Pressure", "description": "Enter your blood pressure measurements manually"
    }], "pageNextText": "Submit"
};
const structureWeight = {
    "title": "BMI", "pages": [{
        "name": "BMI", "elements": [{
            "type": "text", "name": "Weight (kg)", "title": "Insert your current weight in kg", "inputType": "number"
        }], "title": "BMI", "description": "Please enter body weight to calculate your BMI"
    }], "pageNextText": "Submit"
}
const structureDiet = {
    "title": "DIET", "pages": [{
        "name": "Diet",
        "elements": [{
            "type": "radiogroup",
            "name": "Colazione",
            "title": "How much did you eat at yesterday's breakfast?",
            "choices": [{"value": "0", "text": "Skipped"}, {"value": "1", "text": "Light"}, {
                "value": "2",
                "text": "Normal"
            }, {"value": "3", "text": "Abundant"}, {"value": "4", "text": "Excessive"}]
        }, {
            "type": "radiogroup",
            "name": "Spuntino_meta_mattinata",
            "title": "How much did you eat at yesterday's morning snack?",
            "choices": [{"value": "0", "text": "Skipped"}, {"value": "1", "text": "Light"}, {
                "value": "2",
                "text": "Normal"
            }, {"value": "3", "text": "Abundant"}, {"value": "4", "text": "Excessive"}]
        }, {
            "type": "radiogroup",
            "name": "Pranzo",
            "title": "How much did you eat at yesterday's lunch?",
            "choices": [{"value": "0", "text": "Skipped"}, {"value": "1", "text": "Light"}, {
                "value": "2",
                "text": "Normal"
            }, {"value": "3", "text": "Abundant"}, {"value": "4", "text": "Excessive"}]
        }, {
            "type": "radiogroup",
            "name": "Spuntino_pomeridiano",
            "title": "How much did you eat at yesterday's afternoon snack?",
            "choices": [{"value": "0", "text": "Skipped"}, {"value": "1", "text": "Light"}, {
                "value": "2",
                "text": "Normal"
            }, {"value": "3", "text": "Abundant"}, {"value": "4", "text": "Excessive"}]
        }, {
            "type": "radiogroup",
            "name": "Cena",
            "title": "How much did you eat at yesterday's dinner?",
            "choices": [{"value": "0", "text": "Skipped"}, {"value": "1", "text": "Light"}, {
                "value": "2",
                "text": "Normal"
            }, {"value": "3", "text": "Abundant"}, {"value": "4", "text": "Excessive"}]
        }, {
            "type": "radiogroup",
            "name": "Spuntino_serale",
            "title": "How much did you eat at yesterday's midnight snack?",
            "choices": [{"value": "0", "text": "Skipped"}, {"value": "1", "text": "Light"}, {
                "value": "2",
                "text": "Normal"
            }, {"value": "3", "text": "Abundant"}, {"value": "4", "text": "Excessive"}]
        }, {
            "type": "radiogroup",
            "name": "Sale",
            "title": "Did you add salt to at least one of your meal?",
            "choices": ["Yes", "No"]
        }, {
            "type": "text",
            "name": "Acqua",
            "title": "How many glass of water did you drink?",
            "description": "(note: each glass corresponds to 250ml)",
            "inputType": "number"
        }],
        "title": "Diet",
        "description": "Let us know your eating habits answering these simple questions"
    }], "pageNextText": "Submit"
}
const structureActivity = {
    "title": "ACTIVITY",
    "pages": [{
        "name": "Activity",
        "elements": [{
            "type": "text",
            "name": "number_of_activity",
            "title": "How many times have you walked or used a bike in the past week? ",
            "inputType": "number"
        }, {
            "type": "text",
            "name": "minute_of_activity",
            "title": "For how many minutes each time?",
            "inputType": "number"
        }],
        "title": "Physical Activity",
        "description": "How much have you been active in the past week? Please answer the following questions"
    }],
    "pageNextText": "Submit"
}
const structureHabits = {
    "title": "HABITS",
    "pages": [{
        "name": "HABITS",
        "elements": [{
            "type": "text",
            "name": "Sigarette",
            "title": "How many cigarettes/cigars did you smoke per day in the past week?",
            "inputType": "number"
        }, {
            "type": "text",
            "name": "Alcool",
            "title": "How many glass of alcool did you drink per day in the past week?",
            "description": "A \"glass\" must be described depending on type of alcool (es: 400 ml of beer = 200 ml of win = 35 ml of spirits)",
            "inputType": "number"
        }, {
            "type": "text",
            "name": "Caffe",
            "title": "How many cups of coffee did you drink per day in the past week?",
            "inputType": "number"
        }],
        "title": "Your Habits",
        "description": "And now let's talk about your other (bad) habits..."
    }],
    "pageNextText": "Submit"
}

class Insert extends Component {
    state = {
        type: "pressure", sendedData: false, error: false, loading: false,
        loadingStart: true, activeDrugs: [],
        water: 0, salt: "No", dinnerSnack: 0, dinner: 0, snack: 0, lunch: 0, brunch: 0, breakfast: 0,

    }

    async componentDidMount() {
        let values = queryString.parse(this.props.location.search)
        let {changeTranslation} = this.props;
        changeTranslation(values.language)
        if (values.type === "therapy") {
            await this.getTherapyStructure(values)
        }
        this.setState({...values, loadingStart: false})
    }

    getTherapyStructure = async (values) => {

        try {
            let {idPaziente, language, token} = values;

            let res = await axios({
                method: 'post',
                url: "https://salty.tech4care.eu/api/proxy/proxy.php",
                data: {
                    url: "https://hyperhealth.gesan.it/api/pazientiterapia?language=" + language + "&idPaziente=" + idPaziente,
                    token
                },
                headers: {
                    "APIKEY": "AJKBFDJKAHFDB769JAHDNKL",
                    "USERID": idPaziente
                }
            })
            let resTherapyDoses = await axios({
                method: 'post',
                url: "https://salty.tech4care.eu/api/proxy/proxy.php",
                data: {
                    url: "https://hyperhealth.gesan.it/api/pazienteterapiesomministrazioni?language=en&idPaziente=" + idPaziente,
                    token
                },
                headers: {
                    "APIKEY": "AJKBFDJKAHFDB769JAHDNKL",
                    "USERID": idPaziente
                }
            })
            console.log("Controlla somministrazione: ", resTherapyDoses.data.data)


            let activeDrugs = []
            res.data.data.forEach(x => {
                if (x.stato === "ATTIVA") {
                    let tmp = {
                        name: x.farmaco,
                        quantity: x.quantita,
                        format: x.posologia,
                        id: x.id,
                    }
                    if (x.frequenza.includes("24")) {
                        tmp.frequency = "24h"
                        activeDrugs.push({
                            ...tmp,
                            startDate: "00:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "00:00", 24),
                            doseNumber: 1
                        });
                    } else if (x.frequenza.includes("12")) {
                        tmp.frequency = "12h"
                        activeDrugs.push({
                            ...tmp,
                            startDate: "00:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "00:00", 12),
                            doseNumber: 1
                        });
                        activeDrugs.push({
                            ...tmp,
                            startDate: "12:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "12:00", 12),
                            doseNumber: 2
                        });
                    } else if (x.frequenza.includes("8")) {
                        tmp.frequency = "8h"
                        activeDrugs.push({
                            ...tmp,
                            startDate: "00:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "00:00", 6),
                            doseNumber: 1
                        });
                        activeDrugs.push({
                            ...tmp,
                            startDate: "08:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "08:00", 8),
                            doseNumber: 2
                        });
                        activeDrugs.push({
                            ...tmp,
                            startDate: "16:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "16:00", 8),
                            doseNumber: 3
                        });
                    } else if (x.frequenza.includes("6")) {
                        tmp.frequency = "6h"
                        activeDrugs.push({
                            ...tmp,
                            startDate: "00:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "00:00", 6),
                            doseNumber: 1
                        });
                        activeDrugs.push({
                            ...tmp,
                            startDate: "06:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "06:00", 6),
                            doseNumber: 2
                        });
                        activeDrugs.push({
                            ...tmp,
                            startDate: "12:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "12:00", 6),
                            doseNumber: 3
                        });
                        activeDrugs.push({
                            ...tmp,
                            startDate: "18:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "18:00", 6),
                            doseNumber: 4
                        });
                    } else if (x.frequenza.includes("4")) {
                        tmp.frequency = "4h"
                        activeDrugs.push({
                            ...tmp,
                            startDate: "00:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "00:00", 4),
                            doseNumber: 1
                        });
                        activeDrugs.push({
                            ...tmp,
                            startDate: "04:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "04:00", 4),
                            doseNumber: 2
                        });
                        activeDrugs.push({
                            ...tmp,
                            startDate: "08:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "08:00", 4),
                            doseNumber: 3
                        });
                        activeDrugs.push({
                            ...tmp,
                            startDate: "12:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "12:00", 4),
                            doseNumber: 4
                        });
                        activeDrugs.push({
                            ...tmp,
                            startDate: "16:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "16:00", 4),
                            doseNumber: 5
                        });
                        activeDrugs.push({
                            ...tmp,
                            startDate: "20:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "20:00", 4),
                            doseNumber: 6
                        });

                    } else {
                        tmp.frequency = "mps"
                        activeDrugs.push({
                            ...tmp,
                            startDate: "00:00",
                            state: this.getSingleDrug(resTherapyDoses, tmp, "00:00", 24),
                            doseNumber: 1
                        });
                    }
                }
            })
            this.setState({activeDrugs})

        } catch (e) {
            console.error("Error recovering therapy", e, e.response)
        }

    }

    getSingleDrug(resTherapyDoses, tmp, startDate, hourToAdd) {

        let singleDrug = resTherapyDoses.data.data.find(y => {
            return y.idTerapia === tmp.id
                &&
                (
                    moment(y.statoDataOra).isBetween(moment(startDate, "HH:mm"), moment(startDate, "HH:mm").add(hourToAdd, "h"))
                    ||
                    moment(y.statoDataOra).isSame(moment(startDate, "HH:mm"))
                )
        });
        return singleDrug ? singleDrug.stato : "notDefinedYet";
    }

    submitPressure = async (e) => {
        try {
            this.setState({loading: true})
            e.preventDefault()
            let {token, identifierCode, idPaziente} = this.state;
            let surveyPDF = new SurveyPDF.SurveyPDF(structurePressure, {
                fontSize: 14, margins: {
                    left: 10, right: 10, top: 10, bot: 10
                }, format: [210, 297], commercial: true
            });
            surveyPDF.data = {
                "Systolic Blood Pressure (mmHg)": e.target["systolic"].value,
                "Diastolic Blood Pressure (mmHg)": e.target["diastolic"].value
            };
            surveyPDF.mode = "display";
            let pdf = await surveyPDF.raw("dataurlstring");
            let config = {
                method: 'post', url: 'https://salty.tech4care.eu/api/proxy/sendPressure.php', headers: {
                    'APIKEY': 'AJKBFDJKAHFDB769JAHDNKL', 'Content-Type': 'application/json'
                }, data: {
                    systolic: e.target["systolic"].value,
                    diastolic: e.target["diastolic"].value,
                    idPatient: idPaziente,
                    codiceFiscale: identifierCode,
                    token: token,
                    "pdf": pdf
                }
            };

            let res = await axios(config);
            if (res) {
                this.setState({sendedData: true})
            }
        } catch (e) {
            console.error("ERROR SENDING PRESSURE: ", e)
            this.setState({error: true})
        } finally {
            this.setState({loading: false})
        }

    }
    submitWeight = async (e) => {
        try {
            this.setState({loading: true})
            e.preventDefault()
            let {token, identifierCode, idPaziente} = this.state;
            let surveyPDF = new SurveyPDF.SurveyPDF(structureWeight, {
                fontSize: 14, margins: {
                    left: 10, right: 10, top: 10, bot: 10
                }, format: [210, 297], commercial: true
            });
            surveyPDF.data = {
                "Weight (kg)": e.target["weight"].value
            };
            surveyPDF.mode = "display";
            let pdf = await surveyPDF.raw("dataurlstring");
            let config = {
                method: 'post', url: 'https://salty.tech4care.eu/api/proxy/sendWeight.php', headers: {
                    'APIKEY': 'AJKBFDJKAHFDB769JAHDNKL', 'Content-Type': 'application/json'
                }, data: {
                    weight: e.target["weight"].value,
                    idPatient: idPaziente,
                    codiceFiscale: identifierCode,
                    token: token,
                    "pdf": pdf
                }
            };

            let res = await axios(config);
            if (res) {
                this.setState({sendedData: true})
            }
        } catch (e) {
            console.error("ERROR SENDING WEIGHT: ", e)
            this.setState({error: true})
        } finally {
            this.setState({loading: false})
        }

    }
    submitDiet = async (e) => {
        try {
            this.setState({loading: true})
            e.preventDefault()
            let {token, identifierCode, idPaziente} = this.state;
            let {water, salt, dinnerSnack, dinner, snack, lunch, brunch, breakfast} = this.state

            let surveyPDF = new SurveyPDF.SurveyPDF(structureDiet, {
                fontSize: 14, margins: {
                    left: 10, right: 10, top: 10, bot: 10
                }, format: [210, 297], commercial: true
            });
            surveyPDF.data = {
                "Colazione": breakfast,
                "Spuntino_meta_mattinata": brunch,
                "Pranzo": lunch,
                "Spuntino_pomeridiano": snack,
                "Cena": dinner,
                "Spuntino_serale": dinnerSnack,
                "Sale": salt,
                "Acqua": water
            };
            surveyPDF.mode = "display";
            let pdf = await surveyPDF.raw("dataurlstring");
            let config = {
                method: 'post',
                url: 'https://salty.tech4care.eu/api/proxy/sendDiet.php',
                headers: {
                    'APIKEY': 'AJKBFDJKAHFDB769JAHDNKL', 'Content-Type': 'application/json'
                },
                data: {
                    water,
                    salt,
                    dinnerSnack,
                    dinner,
                    snack,
                    lunch,
                    brunch,
                    breakfast,
                    idPatient: idPaziente,
                    codiceFiscale: identifierCode,
                    token: token,
                    "pdf": pdf
                }
            };

            let res = await axios(config);
            if (res) {
                this.setState({sendedData: true})
            }
        } catch (e) {
            console.error("ERROR SENDING WEIGHT: ", e)
            this.setState({error: true})
        } finally {
            this.setState({loading: false})
        }

    }
    submitActivity = async (e) => {
        try {
            this.setState({loading: true})
            e.preventDefault()
            let {token, identifierCode, idPaziente} = this.state;

            let surveyPDF = new SurveyPDF.SurveyPDF(structureActivity, {
                fontSize: 14, margins: {
                    left: 10, right: 10, top: 10, bot: 10
                }, format: [210, 297], commercial: true
            });
            surveyPDF.data = {
                "minute_of_activity": e.target["minute"].value,
                "number_of_activity": e.target["numbers"].value
            };
            surveyPDF.mode = "display";
            let pdf = await surveyPDF.raw("dataurlstring");
            let config = {
                method: 'post',
                url: 'https://salty.tech4care.eu/api/proxy/sendActivity.php',
                headers: {
                    'APIKEY': 'AJKBFDJKAHFDB769JAHDNKL', 'Content-Type': 'application/json'
                },
                data: {
                    number: e.target["numbers"].value,
                    minute: e.target["minute"].value,
                    idPatient: idPaziente,
                    codiceFiscale: identifierCode,
                    token: token,
                    "pdf": pdf
                }
            };

            let res = await axios(config);
            if (res) {
                this.setState({sendedData: true})
            }
        } catch (e) {
            console.error("ERROR SENDING WEIGHT: ", e)
            this.setState({error: true})
        } finally {
            this.setState({loading: false})
        }

    }
    submitHabits = async (e) => {
        try {
            this.setState({loading: true})
            e.preventDefault()
            let {token, identifierCode, idPaziente} = this.state;

            let surveyPDF = new SurveyPDF.SurveyPDF(structureHabits, {
                fontSize: 14, margins: {
                    left: 10, right: 10, top: 10, bot: 10
                }, format: [210, 297], commercial: true
            });
            surveyPDF.data = {
                "Caffe": e.target["coffee"].value,
                "Sigarette": e.target["cigarettes"].value,
                "Alcool": e.target["alcohol"].value
            };
            surveyPDF.mode = "display";
            let pdf = await surveyPDF.raw("dataurlstring");
            let config = {
                method: 'post',
                url: 'https://salty.tech4care.eu/api/proxy/sendHabits.php',
                headers: {
                    'APIKEY': 'AJKBFDJKAHFDB769JAHDNKL', 'Content-Type': 'application/json'
                },
                data: {
                    coffee: e.target["coffee"].value,
                    alcohol: e.target["alcohol"].value,
                    cigarettes: e.target["cigarettes"].value,
                    idPatient: idPaziente,
                    codiceFiscale: identifierCode,
                    token: token,
                    "pdf": pdf
                }
            };

            let res = await axios(config);
            if (res) {
                this.setState({sendedData: true})
            }
        } catch (e) {
            console.error("ERROR SENDING WEIGHT: ", e)
            this.setState({error: true})
        } finally {
            this.setState({loading: false})
        }

    }
    saveDrugDose = async (drugDose,state) => {
        try {
            this.setState({loading: true})
            let {token, identifierCode, idPaziente} = this.state;

            let config = {
                method: 'post',
                url: 'https://salty.tech4care.eu/api/proxy/submitTherapy.php',
                headers: {
                    'APIKEY': 'AJKBFDJKAHFDB769JAHDNKL', 'Content-Type': 'application/json'
                },
                data: {
                    "idTerapia": drugDose.id,
                    "quantity": drugDose.quantity,
                    "date": moment(drugDose.startDate, "HH:mm").unix() + "000",
                    "state": state,
                    "idPaziente": idPaziente,
                    "token": token
                }
            };

            let res = await axios(config);
            if (res) {
                this.setState({sendedData: true})
            }
        } catch (e) {
            console.error("ERROR SENDING WEIGHT: ", e)
            this.setState({error: true})
        } finally {
            this.setState({loading: false})
        }

    }

    //Poteva essere un componente esterno si
    renderSlider = (label) => {
        return <Grid container justifyContent={"center"}>
            <Grid item>
                <span className={style.text2}>
                    <FormattedMessage id={"dashboard.me.insert." + label}/>
                </span>
                <Box sx={{paddingLeft: "15%", paddingRight: "15%", marginTop: "30px"}}>
                    <Slider

                        aria-label={label}
                        defaultValue={0}
                        onChange={(e, value) => {
                            this.setState({
                                [label]: value
                            })
                        }
                        }
                        valueLabelDisplay="on"
                        valueLabelFormat={() => {
                            switch (this.state[label]) {
                                case 0:
                                    return <FormattedMessage id={"dashboard.me.generic.skipped"}/>
                                case 1:
                                    return <FormattedMessage id={"dashboard.me.generic.light"}/>
                                case 2:
                                    return <FormattedMessage id={"dashboard.me.generic.normal"}/>
                                case 3:
                                    return <FormattedMessage id={"dashboard.me.generic.abundant"}/>
                                case 4:
                                    return <FormattedMessage id={"dashboard.me.generic.excessive"}/>
                                default:
                                    return this.state[label];
                            }
                        }
                        }
                        step={1}
                        marks
                        min={0}
                        max={4}
                    />
                </Box>
                <input type={"hidden"} value={this.state[label]}/>
            </Grid>
        </Grid>
    }

    render() {
        let {type, sendedData, error, loading, loadingStart, activeDrugs} = this.state;
        if (loadingStart) {
            return <div className={style.loadingModal}>
                <div className={style.loadingContainer}>
                    <span
                        className={style.text2}>
                        <FormattedMessage id={"dashboard.me.view.loading"}/>
                    </span>
                </div>
            </div>
        }
        let output;
        if (sendedData) {
            return (<div className={style.background}>
                <div className={style.form}>
                    <span className={style.text1}><FormattedMessage id={"dashboard.me.insert.dataSended"}/></span>
                    <Button onClick={() => {
                        this.props.history.push("/view" + this.props.location.search);
                        this.setState({
                            sendedData: false, error: false,
                        })
                    }} variant="outlined" color={"primary"}><FormattedMessage
                        id={"dashboard.me.insert.goBack"}/></Button>
                </div>
            </div>);
        }
        if (error) {
            return (<div className={style.background}>
                <div className={style.form}>
                    <span className={style.text1}><FormattedMessage id={"dashboard.me.insert.error"}/></span>
                    <Button onClick={() => {
                        this.setState({
                            sendedData: false, error: false,
                        })
                    }} variant="outlined" color={"primary"}><FormattedMessage
                        id={"dashboard.me.insert.retry"}/></Button>
                </div>
            </div>);
        }
        switch (type) {
            case "pressure":
                output = <form
                    className={style.form}
                    onSubmit={this.submitPressure}
                >
                    <TextField id="systolic" label={<FormattedMessage id={"dashboard.me.insert.systolic"}/>}
                               variant="outlined"
                               type={"number"}
                               required={true}
                               inputProps={{
                                   step: 1, min: 20, max: 400, type: 'number'
                               }}
                    />
                    <TextField id="diastolic" label={<FormattedMessage id={"dashboard.me.insert.diastolic"}/>}
                               variant="outlined"
                               type={"number"}
                               required={true}
                               inputProps={{
                                   step: 1, min: 20, max: 400, type: 'number'
                               }}
                    />
                    <Button endIcon={loading && <CircularProgress color={"success"} size={20}/>} type={"submit"}
                            variant="outlined" color={"primary"}
                            disabled={loading}
                    >
                        <FormattedMessage id={"dashboard.me.insert.submit"}/>
                    </Button>
                </form>
                break;
            case "bmi":
                output = <form
                    className={style.form}
                    onSubmit={this.submitWeight}
                >
                    <TextField id="weight" label={<FormattedMessage id={"dashboard.me.insert.weight"}/>}
                               variant="outlined"
                               type={"number"}
                               required={true}
                               inputProps={{
                                   step: 1, min: 20, max: 400, type: 'number'
                               }}
                    />
                    <Button endIcon={loading && <CircularProgress color={"success"} size={20}/>} type={"submit"}
                            variant="outlined" color={"primary"}
                            disabled={loading}
                    >
                        <FormattedMessage id={"dashboard.me.insert.submit"}/>
                    </Button>
                </form>
                break;
            case "diet":
                output = <form
                    className={style.form}
                    onSubmit={this.submitDiet}
                >
                    {this.renderSlider("breakfast")}
                    {this.renderSlider("brunch")}
                    {this.renderSlider("lunch")}
                    {this.renderSlider("snack")}
                    {this.renderSlider("dinner")}
                    {this.renderSlider("dinnerSnack")}
                    <Grid container justifyContent={"center"}>
                        <Grid item>
                            <span className={style.text2}>
                                <FormattedMessage id={"dashboard.me.insert.salt"}/>
                            </span>
                            <Box sx={{paddingLeft: "40%", paddingRight: "40%", marginTop: "30px"}}>
                                <Slider
                                    aria-label={"salt"}
                                    defaultValue={0}
                                    onChange={(e, value) => {
                                        this.setState({
                                            salt: value === 0 ? "No" : "Yes"
                                        })
                                    }
                                    }
                                    valueLabelFormat={() => {
                                        //TODO Missing localization
                                        return this.state.salt
                                    }
                                    }
                                    valueLabelDisplay="on"
                                    step={1}
                                    marks
                                    min={0}
                                    max={1}
                                />
                            </Box>
                            <input type={"hidden"} value={this.state.salt}/>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={"center"}>
                        <Grid item>
                            <span className={style.text2}>
                                <FormattedMessage id={"dashboard.me.insert.water"}/>
                            </span>
                            <Box sx={{paddingLeft: "5%", paddingRight: "5%", marginTop: "30px"}}>
                                <Slider
                                    aria-label={"water"}
                                    defaultValue={0}
                                    onChange={(e, value) => {
                                        this.setState({
                                            water: value
                                        })
                                    }
                                    }
                                    valueLabelFormat={() => {
                                        //TODO Missing localization
                                        return this.state.water + " ml"
                                    }
                                    }
                                    valueLabelDisplay="on"
                                    step={250}
                                    marks
                                    min={0}
                                    max={4000}
                                />
                            </Box>
                            <input type={"hidden"} value={this.state.salt}/>
                        </Grid>
                    </Grid>
                    <Button endIcon={loading && <CircularProgress color={"success"} size={20}/>}
                            disabled={loading}
                            type={"submit"}
                            variant="outlined" color={"primary"}>
                        <FormattedMessage id={"dashboard.me.insert.submit"}/>
                    </Button>
                </form>
                break;
            case "sleep":
                break;
            case "habits":
                output = <form
                    className={style.form}
                    onSubmit={this.submitHabits}
                >
                    <span className={style.text1}><FormattedMessage id={"dashboard.me.insert.habitsTitle"}/></span>
                    <span className={style.text2}><FormattedMessage id={"dashboard.me.insert.habitsCoffee"}/></span>
                    <TextField id="coffee"
                               variant="outlined"
                               type={"number"}
                               required={true}
                               inputProps={{
                                   step: 1, min: 0, max: 999, type: 'number'
                               }}
                    />
                    <span className={style.text2}><FormattedMessage id={"dashboard.me.insert.habitsAlcohol"}/></span>
                    <TextField id="alcohol"
                               variant="outlined"
                               type={"number"}
                               required={true}
                               inputProps={{
                                   step: 1, min: 0, max: 999, type: 'number'
                               }}
                    />
                    <span className={style.text2}><FormattedMessage id={"dashboard.me.insert.habitsCigarettes"}/></span>
                    <TextField id="cigarettes"
                               variant="outlined"
                               type={"number"}
                               required={true}
                               inputProps={{
                                   step: 1, min: 0, max: 999, type: 'number'
                               }}
                    />
                    <Button endIcon={loading && <CircularProgress color={"success"} size={20}/>} type={"submit"}
                            variant="outlined" color={"primary"}
                            disabled={loading}
                    >
                        <FormattedMessage id={"dashboard.me.insert.submit"}/>
                    </Button>
                </form>
                break;
            case "activity":
                output = <form
                    className={style.form}
                    onSubmit={this.submitActivity}
                >
                    <span className={style.text1}><FormattedMessage id={"dashboard.me.insert.activityTitle"}/></span>
                    <span className={style.text2}><FormattedMessage id={"dashboard.me.insert.activityNumber"}/></span>
                    <TextField id="numbers"
                               variant="outlined"
                               type={"number"}
                               required={true}
                               inputProps={{
                                   step: 1, min: 0, max: 30, type: 'number'
                               }}
                    />
                    <span className={style.text2}><FormattedMessage id={"dashboard.me.insert.activityMinute"}/></span>
                    <TextField id="minute"
                               variant="outlined"
                               type={"number"}
                               required={true}
                               inputProps={{
                                   step: 1, min: 0, max: 360, type: 'number'
                               }}
                    />
                    <Button endIcon={loading && <CircularProgress color={"success"} size={20}/>} type={"submit"}
                            variant="outlined" color={"primary"}
                            disabled={loading}>
                        <FormattedMessage id={"dashboard.me.insert.submit"}/>
                    </Button>
                </form>
                break;
            case "therapy":
                output = <div className={style.background}>
                    {activeDrugs && activeDrugs.map((x, index) => {
                        let icon;
                        switch (x.state) {
                            case "RIFIUTATA":
                                icon = <Warning className={style.icon} color={"warning"}
                                    // sx={{color: yellow[500]}}
                                />;
                                break;
                            case "NON SOMMINISTRATA":
                                icon = <Error className={style.icon} color={"error"}
                                    // sx={{color: red[500]}}
                                />;
                                break;
                            case "SOMMINISTRATA":
                                icon = <CheckCircle className={style.icon} color={"success"}
                                    // sx={{color: green[500]}}
                                />;
                                break;
                            case "notDefinedYet":
                            default:

                                break;
                        }

                        return <div key={"card-" + index} className={style.card}

                        >

                            {icon}
                            <span
                                className={style.text3}>
                                {/*{x["startDate"]}*/}
                                <FormattedMessage id={"dashboard.me.insert.therapy.doseNumber." + x.doseNumber}/>
                                </span>
                            <span
                                className={style.text1}>
                                {x.name[0] + x.name.substring(1, x.name.length).toLowerCase()}
                                </span>
                            <span
                                className={style.text3} style={{justifyContent: "space-between", paddingRight: "10px"}}>

                                {x["quantity"]}
                                {" "}
                                {x["format"]}

                            </span>
                               {!icon && <div
                                    style={{display:"flex",justifyContent: "space-between",}}>
                                   <IconButton
                                       color={"error"}

                                       onClick={async () => {
                                           await this.saveDrugDose(x,"NON SOMMINISTRATA")
                                       }}>
                                   {/*<FormattedMessage id={"dashboard.me.insert.therapy.notTaken"}/>*/}
                                       <RemoveDone  color={"error"}
                                                   fontSize={"large"}
                                           // sx={{color: yellow[500]}}
                                       />
                               </IconButton>
                                   <IconButton
                                       color={"warning"}
                                       onClick={async () => {
                                           await this.saveDrugDose(x,"RIFIUTATA")
                                       }}>
                                   {/*<FormattedMessage id={"dashboard.me.insert.therapy.reject"}/>*/}
                                       <Error  color={"warning"}
                                           // sx={{color: red[500]}}
                                              fontSize={"large"}
                                       />
                               </IconButton>
                                   <IconButton
                                   variant={"outlined"}
                                   onClick={async () => {
                                       await this.saveDrugDose(x,"SOMMINISTRATA")
                                   }}>
                                   {/*<FormattedMessage id={"dashboard.me.insert.therapy.submit"}/>*/}
                                       <CheckCircle  color={"success"}
                                           // sx={{color: green[500]}}
                                                    fontSize={"large"}
                                       />
                               </IconButton>

                               </div>}


                        </div>
                    })}
                </div>
                break;
            default:
                break;

        }
        return (<div className={style.background}>
            {output}
        </div>);
    }
}

export default withRouter(Insert);